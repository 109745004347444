import commonActions from '@/common/store/actions'
import axios from '@/utils/axios'

const rootDispatch = { root: true }
const customActions = {
  async loadUserDetail ({ rootState, commit, dispatch }) {
    const { id } = rootState.session.loggedUser
    const { type, payload } = await axios({ url: `users/${id}`, method: 'GET' })
    if (type === 200) {
      commit('USER_DETAIL', payload.data)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async updateUserDetail ({ rootState, commit, dispatch }, data) {
    const { id } = rootState.session.loggedUser
    const { type, payload } = await axios({ url: `users/${id}`, data, method: 'PATCH' })
    if (type === 200) {
      commit('USER_DETAIL', payload.data)
      commit('ADD_LOGGED_USER_DATA', payload.data, rootDispatch)
      dispatch('addNotification', { type: 'success', message: 'User detail successfully updated.' }, rootDispatch)
      return {}
    } else if (type === 422) {
      return payload.errors
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
      return {}
    }
  },
  async updateUserPassword ({ rootState, commit, dispatch }, data) {
    const { id } = rootState.session.loggedUser
    const { type, payload } = await axios({ url: `users/${id}/password`, data, method: 'PATCH' })
    if (type === 200) {
      commit('USER_DETAIL', payload.data)
      dispatch('addNotification', { type: 'success', message: 'Password successfully updated.' }, rootDispatch)
      return {}
    } else if (type === 422) {
      return payload.errors
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
      return {}
    }
  }
}

export default Object.assign(
  {},
  commonActions,
  customActions
)
