<template>
  <CCard accentColor="info">
    <CCardHeader>
      Update Password
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="formInput.current"
            name="current"
            label="Current Password *"
            :isValid="!errors.current ? null : false"
            :invalidFeedback="errors.current"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="formInput.password"
            name="password"
            label="New Password *"
            :isValid="!errors.password ? null : false"
            :invalidFeedback="errors.password"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="formInput.password_confirmation"
            name="password_confirmation"
            label="Confirm New Password *"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CButton
            color="success"
            @click="updateUserPassword"
          >
            Update Password
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import { entity } from '../entity'
export default {
  name: 'UpdatePassword',
  data () {
    return {
      isUpdating: false,
      formInput: {
        current: '',
        password: '',
        password_confirmation: ''
      },
      errors: {}
    }
  },
  methods: {
    async updateUserPassword () {
      const errors = await this.$store.dispatch(`${entity}/updateUserPassword`, this.formInput)
      this.errors = {}
      if (errors) {
        Object.keys(errors).map((key) => {
          this.errors[key] = errors[key][0]
        })
      }
    }
  }
}
</script>
