<template>
  <div>
    <CRow>
      <CCol md="6">
        <update-profile :user="userDetail || {}" />
      </CCol>
      <CCol md="6">
        <update-password />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from './store'

import UpdatePassword from './components/UpdatePassword'
import UpdateProfile from './components/UpdateProfile'

import { entity } from './entity'

export default {
  name: 'ProfilePage',
  title: 'Profile',
  components: {
    UpdatePassword,
    UpdateProfile
  },
  computed: {
    ...mapState({
      userDetail: state => state[entity].userDetail
    })
  },
  beforeMount () {
    if (!this.$store.state[entity]) {
      this.$store.registerModule(entity, store)
    }
  },
  mounted () {
    this.$store.dispatch(`${entity}/loadUserDetail`)
  }
}
</script>
