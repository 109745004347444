import commonState from '@/common/store/state'

import { entity, entitySingle } from '../entity'

const customState = {
  entity,
  entitySingle,
  userDetail: {}
}

export default Object.assign(
  {},
  commonState,
  customState
)
