import commonMutations from '@/common/store/mutations'

const customMutations = {
  USER_DETAIL (state, payload) {
    state.userDetail = payload
  }
}

export default Object.assign(
  {},
  commonMutations,
  customMutations
)
