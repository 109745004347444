<template>
  <CCard accentColor="primary">
    <CCardHeader>
      Update Profile
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="editUser.name"
            name="name"
            label="Name"
            :isValid="!errors.name ? null : false"
            :invalidFeedback="errors.name"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            v-model="user.email"
            name="email"
            label="Email"
            :readonly="true"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CButton
            color="success"
            @click="updateUserDetail"
          >
            Update Detail
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
import { entity } from '../entity'
export default {
  name: 'UpdateProfile',
  props: {
    user: Object
  },
  data () {
    return {
      isUpdating: false,
      editUser: { name: '' },
      errors: {}
    }
  },
  watch: {
    user: function () {
      this.editUser.name = this.user.name
    }
  },
  methods: {
    async updateUserDetail () {
      const errors = await this.$store.dispatch(`${entity}/updateUserDetail`, this.editUser)
      this.errors = {}
      if (errors) {
        Object.keys(errors).map((key) => {
          this.errors[key] = errors[key][0]
        })
      }
    }
  }
}
</script>
